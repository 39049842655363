<template>
  <f7-page name="account">
    <f7-navbar>
      <f7-nav-left>
        <f7-link href="/accounts/" transition="push-reverse">
          <f7-icon icon="icon-back" class="margin-right-half"></f7-icon>
          Zurück
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ account.title }}</f7-nav-title>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title"
          disable-button-text="Abbrechen"
          placeholder="Suchen"
        ></f7-searchbar>
      </f7-subnavbar>
    </f7-navbar>
    <div
      class="padding-horizontal padding-vertical-half bg-color-primary text-color-white bold display-flex justify-content-space-between"
    >
      <div>
        Aktueller Saldo:
      </div>
      <div>
        {{ account.balance | toCurrency }}
      </div>
    </div>
    <f7-list class="no-margin-vertical search-list">
      <f7-list-group v-for="day in transactionsByDay" :key="day.date">
        <f7-list-item
          :title="moment(day.date).format('DD.MM.YYYY')"
          group-title
        ></f7-list-item>
        <f7-list-item
          v-for="transaction in day.transactions"
          :key="transaction.id"
          :title="transaction.title"
          :footer="getTransactionFooter(transaction)"
          @click="onTransactionClick(transaction)"
        >
          <f7-icon
            slot="media"
            :ios="getTransactionIcon(transaction)"
            :md="getTransactionIcon(transaction)"
          ></f7-icon>
          <span :class="getValueClass(transaction)">{{
            getTransactionValue(transaction) | toCurrency
          }}</span>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
import moment from "moment";
import { orderBy } from "lodash";
import authenticatedRoute from "./../mixins/authenticated-route.js";
import AccountQuery from "./../gql/queries/account.gql";

export default {
  mixins: [authenticatedRoute],
  apollo: {
    account: {
      query: AccountQuery,
      variables: function () {
        return {
          id: this.accountId,
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  props: {
    accountId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      account: {},
    };
  },
  computed: {
    transactionsByDay: function () {
      if (
        !this.account.transactions ||
        !this.account.transfersTo ||
        !this.account.transfersFrom
      )
        return [];

      const transactionsByDay = [];

      const transactions = [
        ...this.account.transactions,
        ...this.account.transfersTo,
        ...this.account.transfersFrom,
      ];

      transactions.forEach((t) => {
        //@todo: handle future transactions
        if (
          moment().isBefore(moment(t.date)) ||
          (t.isRecurring && !t.approved)
        ) {
          return;
        }

        let dayObject = transactionsByDay.find(
          (d) => d.date === moment(t.date).format()
        );

        if (!dayObject) {
          dayObject = {
            date: moment(t.date).format(),
            transactions: [],
          };

          transactionsByDay.push(dayObject);
        }

        dayObject.transactions.push(t);
      });

      return orderBy(transactionsByDay, ["date"], ["desc"]);
    },
  },
  methods: {
    getTransactionFooter: function (transaction) {
      if (transaction.categories && transaction.categories.length) {
        return transaction.categories.map((c) => c.title).join(", ");
      }

      if (transaction.fromAccount && transaction.toAccount) {
        return `${transaction.toAccount.title}`;
      }

      return "Ohne Kategorie";
    },
    getTransactionValue: function (transaction) {
      if (transaction.type === "Income") {
        return `+${transaction.value}`;
      }

      if (transaction.type === "Outflow") {
        return `-${transaction.value}`;
      }

      if (transaction.fromAccount.id === this.account.id) {
        return `-${transaction.value}`;
      }

      if (transaction.toAccount.id === this.account.id) {
        return `+${transaction.value}`;
      }

      return transaction.value;
    },
    getValueClass: function (transaction) {
      const isIncome =
        transaction.type === "Income" ||
        (transaction.toAccount && transaction.toAccount.id === this.account.id);

      return isIncome ? "text-color-teal" : "";
    },
    getTransactionIcon: function (transaction) {
      const icon = transaction.account ? "payments" : "compare_arrows";
      return `material:${icon}`;
    },
    onTransactionClick: function (transaction) {
      let route = "";
      let index = null;
      let key = "";

      if (transaction.type === "Income" || transaction.type === "Outflow") {
        route = `/edit-transaction/${transaction.id}/`;

        index = this.account.transactions.findIndex(
          (t) => t.id === transaction.id
        );

        key = "transactions";
      } else {
        route = `/edit-transfer/${transaction.id}/`;

        index = this.account.transfersTo.findIndex(
          (t) => t.id === transaction.id
        );

        key = "transfersTo";

        if (index < 0) {
          index = this.account.transfersFrom.findIndex(
            (t) => t.id === transaction.id
          );

          key = "transfersFrom";
        }
      }

      this.$f7router.navigate(route, {
        props: {
          onUpdate: (updated) => {
            this.account[key].splice(index, 1, updated);
          },
          onDelete: () => {
            this.account[key].splice(index, 1);
          },
        },
      });
    },
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>
