<template>
  <f7-popup id="new-transfer-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Neuer Transfer">
          <f7-nav-right>
            <f7-link popup-close>
              Schließen
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <transfer-form :transfer="transfer"></transfer-form>
        <f7-block>
          <f7-row>
            <f7-col>
              <f7-button large raised fill @click="createTransfer()">
                Anlegen
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import moment from "moment";
import showToast from "./../mixins/show-toast.js";
import TransferFormComponent from "./forms/transfer.vue";

import CreateTransferMutation from "./../gql/mutations/create-transfer.gql";

export default {
  components: {
    transferForm: TransferFormComponent,
  },
  mixins: [showToast],
  data() {
    return {
      transfer: {
        date: [moment().toDate()],
      },
    };
  },
  methods: {
    async createTransfer() {
      const input = {
        data: {
          ...this.transfer,
        },
      };

      input.data.date = moment(input.data.date[0]).format();
      input.data.value = parseFloat((input.data.value * 100).toFixed(0));

      try {
        await this.$apollo.mutate({
          mutation: CreateTransferMutation,
          variables: {
            input,
          },
        });

        const popupElement = document.getElementById("new-transfer-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        this.showToast("Transfer wurde erfolgreich angelegt!");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
