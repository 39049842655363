export default {
  methods: {
    showToast(message, time = 3000) {
      let toast = this.$f7.toast.create({
        text: message,
        closeTimeout: time,
        position: "bottom",
      });
      toast.open();
    },
  },
};
