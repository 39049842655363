<template>
  <f7-popup id="edit-transaction-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Transaktion bearbeiten">
          <f7-nav-right>
            <f7-link popup-close>
              Schließen
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <transaction-form :transaction="transactionToEdit"></transaction-form>
        <f7-block>
          <f7-row>
            <f7-col>
              <f7-button large raised fill @click="saveTransaction()">
                Speichern
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <f7-button
                large
                raised
                fill
                color="red"
                class="margin-top-half"
                @click="confirmDelete()"
              >
                Löschen
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import showToast from "./../mixins/show-toast.js";
import TransactionFormComponent from "./forms/transaction.vue";

import TransactionQuery from "./../gql/queries/transaction.gql";
import UpdateTransactionMutation from "./../gql/mutations/update-transaction.gql";
import DeleteTransactionMutation from "./../gql/mutations/delete-transaction.gql";

export default {
  components: {
    transactionForm: TransactionFormComponent,
  },
  mixins: [showToast],
  apollo: {
    transaction: {
      query: TransactionQuery,
      variables: function () {
        return {
          id: this.transactionId,
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  props: {
    transactionId: {
      type: String,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      transaction: {},
      updateQuery: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " €",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    transactionToEdit: function () {
      const t = cloneDeep(this.transaction);
      t.account = t.account ? t.account.id : null;
      t.categories = t.categories ? t.categories.map((c) => c.id) : [];
      t.date = [moment(t.date).toDate()];
      t.value = t.value / 100;

      if (t.categoryValues) {
        const categoryValues = {};
        t.categoryValues.forEach((cv) => {
          categoryValues[cv.category.id] = cv.value / 100;
        });
        t.categoryValues = categoryValues;
      }

      return t;
    },
  },
  methods: {
    confirmDelete() {
      this.$f7.dialog.confirm(
        "Sind Sie sicher, dass Sie diese Transaktion löschen wollen?",
        "Transaktion löschen",
        () => {
          this.deleteTransaction();
        }
      );
    },
    async deleteTransaction() {
      const input = {
        where: {
          id: this.transactionToEdit.id,
        },
      };

      try {
        await this.$apollo.mutate({
          mutation: DeleteTransactionMutation,
          variables: {
            input,
          },
        });

        const popupElement = document.getElementById("edit-transaction-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        if (typeof this.onDelete === "function") {
          this.onDelete();
        }

        this.showToast("Transaktion wurde erfolgreich gelöscht!");
      } catch (e) {
        console.log(e);
      }
    },
    async saveTransaction() {
      const input = {
        where: {
          id: this.transactionToEdit.id,
        },
        data: {
          ...this.transactionToEdit,
        },
      };

      Object.keys(this.transactionToEdit.categoryValues).forEach((cId) => {
        if (this.transactionToEdit.categories.includes(cId)) return;

        delete input.data.categoryValues[cId];
      });

      input.data.categoryValues = Object.keys(input.data.categoryValues).map(
        (cId) => {
          return {
            category: cId,
            value: input.data.categoryValues[cId],
          };
        }
      );

      input.data.date = moment(input.data.date[0]).format();
      delete input.data.__typename;
      delete input.data.id;

      if (this.transactionToEdit.categories.length) {
        input.data.value = Object.values(
          this.transactionToEdit.categoryValues
        ).reduce((sum, v) => sum + v, 0);
      } else {
        input.data.value = parseFloat((input.data.value * 100).toFixed(0));
      }

      try {
        const {
          data: {
            updateTransaction: { transaction },
          },
        } = await this.$apollo.mutate({
          mutation: UpdateTransactionMutation,
          variables: {
            input,
          },
        });

        if (typeof this.onUpdate === "function") {
          this.onUpdate(transaction);
        }

        const popupElement = document.getElementById("edit-transaction-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        this.showToast("Transaktion wurde erfolgreich aktualisiert!");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
