import LoginPage from "../pages/login.vue";
import BudgetSelectPage from "../pages/budget-select.vue";
import BudgetPage from "../pages/budget.vue";
import BudgetsPage from "../pages/budgets.vue";
import AccountPage from "../pages/account.vue";
import AccountsPage from "../pages/accounts.vue";
import AllAccountsPage from "../pages/all-accounts.vue";

import NewTransferPopupComponent from "../components/new-transfer-popup.vue";
import NewTransactionPopupComponent from "../components/new-transaction-popup.vue";
import EditTransferPopupComponent from "../components/edit-transfer-popup.vue";
import EditTransactionPopupComponent from "../components/edit-transaction-popup.vue";

var routes = [
  {
    path: "/budget/:budgetId/",
    component: BudgetPage,
  },
  {
    path: "/budget-select/",
    component: BudgetSelectPage,
  },
  {
    path: "/budgets/",
    component: BudgetsPage,
  },
  {
    path: "/accounts/",
    component: AccountsPage,
  },
  {
    path: "/all-accounts/",
    component: AllAccountsPage,
  },
  {
    path: "/account/:accountId/",
    component: AccountPage,
  },
  {
    path: "/login/",
    component: LoginPage,
  },
  {
    path: "/new-transfer/",
    popup: {
      component: NewTransferPopupComponent,
    },
  },
  {
    path: "/new-transaction/",
    popup: {
      component: NewTransactionPopupComponent,
    },
  },
  {
    path: "/edit-transfer/:transferId/",
    popup: {
      component: EditTransferPopupComponent,
    },
  },
  {
    path: "/edit-transaction/:transactionId/",
    popup: {
      component: EditTransactionPopupComponent,
    },
  },
];

export default routes;
