<template>
  <f7-page name="login">
    <f7-page login-screen>
      <f7-login-screen-title class="text-color-primary">
        Pennywise
      </f7-login-screen-title>
      <f7-list form>
        <f7-list-input
          label="E-Mail"
          type="email"
          placeholder="Ihre E-Mail-Adresse"
          :value="userData.identifier"
          @input="userData.identifier = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="Passwort"
          type="password"
          placeholder="Ihr Passwort"
          :value="userData.password"
          @input="userData.password = $event.target.value"
        ></f7-list-input>
      </f7-list>
      <f7-list>
        <f7-list-button @click="signIn">
          Login
        </f7-list-button>
      </f7-list>
    </f7-page>
  </f7-page>
</template>

<script>
import LoginMutation from "./../gql/mutations/login.gql";
import showToast from "./../mixins/show-toast.js";

export default {
  mixins: [showToast],
  data() {
    return {
      userData: {
        identifier: process.env.PENNYWISE.LOGIN_USER,
        password: process.env.PENNYWISE.LOGIN_PW,
      },
    };
  },
  mounted() {
    const loginData = JSON.parse(localStorage.getItem("loginData"));

    if (loginData) {
      this.$store.dispatch("setToken", loginData.jwt);
      this.$store.dispatch("setUserId", loginData.user.id);
      this.$store.dispatch("setUsername", loginData.user.username);

      this.$f7ready(() => {
        this.$f7.dialog.preloader("Anmeldung läuft...");

        setTimeout(() => {
          const selectedBudget = localStorage.getItem("selectedBudget");

          if (selectedBudget) {
            this.$store.dispatch("setBudget", selectedBudget);
            this.$f7router.navigate(`/budget/${selectedBudget}/`);
          } else {
            this.$f7router.navigate("/budget-select/");
          }

          this.$f7.dialog.close();
        }, 500);
      });
    }
  },
  methods: {
    async signIn() {
      this.$f7.dialog.preloader("Anmeldung läuft...");

      try {
        const {
          data: { login: loginData },
        } = await this.$apollo.mutate({
          mutation: LoginMutation,
          variables: {
            input: this.userData,
          },
        });

        if (loginData.jwt) {
          localStorage.setItem(
            "loginData",
            JSON.stringify({
              jwt: loginData.jwt,
              user: {
                id: loginData.user.id,
                username: loginData.user.username,
              },
            })
          );

          this.$store.dispatch("setToken", loginData.jwt);
          this.$store.dispatch("setUserId", loginData.user.id);
          this.$store.dispatch("setUsername", loginData.user.username);

          this.$f7router.navigate("/budget-select/");
        }
      } catch (e) {
        this.showToast("Login fehlgeschlagen!");
      } finally {
        this.$f7.dialog.close();
      }
    },
  },
};
</script>
