export default {
  mounted() {
    this.$f7ready(() => {
      let router = this.$f7router;
      let token = this.$store.state.user.token;
      if (!token) {
        console.log("UNATHENTICATED");
        router.navigate("/login/");
      }
    });
  },
};
