var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-list',{staticClass:"no-margin-vertical",attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"label":"Typ","input":false}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.transaction.type),expression:"transaction.type"}],attrs:{"slot":"input","name":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.transaction, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}},slot:"input"},[_c('option',{attrs:{"value":"Outflow"}},[_vm._v("\n        Ausgabe\n      ")]),_vm._v(" "),_c('option',{attrs:{"value":"Income"}},[_vm._v("\n        Einnahme\n      ")])])]),_vm._v(" "),_c('f7-list-input',{attrs:{"label":"Datum","type":"datepicker","placeholder":"Datum der Transaktion","value":_vm.transaction.date,"readonly":""},on:{"calendar:change":function (value) { return (_vm.transaction.date = value); }}}),_vm._v(" "),_c('f7-list-item',{attrs:{"title":"Konto","smart-select":"","smart-select-params":{
      openIn: 'sheet',
      sheetCloseLinkText: 'Fertig',
    }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.transaction.account),expression:"transaction.account"}],attrs:{"name":"account"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.transaction, "account", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.accounts.filter(function (a) { return !a.isClosed; })),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n        "+_vm._s(account.title)+"\n      ")])}),0)]),_vm._v(" "),_c('f7-list-item',{attrs:{"title":"Kategorie","smart-select":"","smart-select-params":{
      openIn: 'popup',
      searchbar: true,
      searchbarPlaceholder: 'Suche nach Kategorien...',
      popupCloseLinkText: 'Schließen',
      searchbarDisableText: 'Abbrechen',
    }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.transaction.categories),expression:"transaction.categories"}],attrs:{"name":"categories","multiple":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.transaction, "categories", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categorygroups),function(categorygroup){return _c('optgroup',{key:categorygroup.id,attrs:{"label":categorygroup.title}},_vm._l((categorygroup.categories),function(category){return _c('option',{key:category.id,domProps:{"value":category.id}},[_vm._v("\n          "+_vm._s(category.title)+"\n        ")])}),0)}),0)]),_vm._v(" "),_c('f7-list-input',{attrs:{"label":"Beschreibung","type":"text","placeholder":"Beschreibung der Transaktion","clear-button":"","value":_vm.transaction.title},on:{"input":function($event){_vm.transaction.title = $event.target.value}}}),_vm._v(" "),(_vm.transaction.categories.length)?_c('ul',_vm._l((_vm.transaction.categories),function(category){return _c('f7-list-input',{key:category.id,attrs:{"label":'Betrag (' + _vm.getCategoryTitleById(category) + ')',"input":false}},[_c('money',_vm._b({attrs:{"slot":"input","value":_vm.transaction.categoryValues[category]},on:{"input":function($event){return _vm.setCategoryValue(category, $event)}},slot:"input"},'money',_vm.money,false))],1)}),1):_c('ul',[_c('f7-list-input',{attrs:{"label":"Betrag","input":false}},[_c('money',_vm._b({attrs:{"slot":"input"},slot:"input",model:{value:(_vm.transaction.value),callback:function ($$v) {_vm.$set(_vm.transaction, "value", $$v)},expression:"transaction.value"}},'money',_vm.money,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }