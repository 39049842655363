<template>
  <f7-page name="accounts">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:menu"
          icon
          icon-md="material:menu"
          panel-open="left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Kontenübersicht</f7-nav-title>
    </f7-navbar>
    <f7-list class="no-margin-vertical">
      <f7-list-item title="Alle Konten" link="/all-accounts/">
        <f7-icon slot="media" ios="material:apps" md="material:apps"></f7-icon>
        <span>{{ totalBalance | toCurrency }}</span>
      </f7-list-item>
      <f7-list-item
        v-for="account in accounts"
        :key="account.id"
        :link="'/account/' + account.id + '/'"
        :title="account.title"
      >
        <span>
          {{ account.balance | toCurrency }}
        </span>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import AccountsQuery from "./../gql/queries/accounts.gql";
import authenticatedRoute from "./../mixins/authenticated-route.js";

export default {
  mixins: [authenticatedRoute],
  apollo: {
    accounts: {
      query: AccountsQuery,
      variables: function () {
        return {
          where: {
            budget: this.selectedBudget,
          },
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  computed: {
    totalBalance: function () {
      return this.accounts.reduce((sum, a) => {
        sum += a.balance;
        return sum;
      }, 0);
    },
  },
  data() {
    return {
      accounts: [],
      selectedBudget: this.$store.state.budget,
    };
  },
};
</script>
