<template>
  <f7-list class="no-margin-vertical" no-hairlines-md>
    <f7-list-input label="Typ" :input="false">
      <select slot="input" v-model="transaction.type" name="type">
        <option value="Outflow">
          Ausgabe
        </option>
        <option value="Income">
          Einnahme
        </option>
      </select>
    </f7-list-input>

    <f7-list-input
      label="Datum"
      type="datepicker"
      placeholder="Datum der Transaktion"
      :value="transaction.date"
      readonly
      @calendar:change="(value) => (transaction.date = value)"
    ></f7-list-input>

    <f7-list-item
      title="Konto"
      smart-select
      :smart-select-params="{
        openIn: 'sheet',
        sheetCloseLinkText: 'Fertig',
      }"
    >
      <select v-model="transaction.account" name="account">
        <option
          v-for="account in accounts.filter((a) => !a.isClosed)"
          :key="account.id"
          :value="account.id"
        >
          {{ account.title }}
        </option>
      </select>
    </f7-list-item>

    <f7-list-item
      title="Kategorie"
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Suche nach Kategorien...',
        popupCloseLinkText: 'Schließen',
        searchbarDisableText: 'Abbrechen',
      }"
    >
      <select v-model="transaction.categories" name="categories" multiple>
        <optgroup
          v-for="categorygroup in categorygroups"
          :key="categorygroup.id"
          :label="categorygroup.title"
        >
          <option
            v-for="category in categorygroup.categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.title }}
          </option>
        </optgroup>
      </select>
    </f7-list-item>

    <f7-list-input
      label="Beschreibung"
      type="text"
      placeholder="Beschreibung der Transaktion"
      clear-button
      :value="transaction.title"
      @input="transaction.title = $event.target.value"
    ></f7-list-input>

    <ul v-if="transaction.categories.length">
      <f7-list-input
        v-for="category in transaction.categories"
        :key="category.id"
        :label="'Betrag (' + getCategoryTitleById(category) + ')'"
        :input="false"
      >
        <money
          slot="input"
          v-bind="money"
          :value="transaction.categoryValues[category]"
          @input="setCategoryValue(category, $event)"
        ></money>
      </f7-list-input>
    </ul>

    <ul v-else>
      <f7-list-input label="Betrag" :input="false">
        <money slot="input" v-model="transaction.value" v-bind="money"></money>
      </f7-list-input>
    </ul>
  </f7-list>
</template>

<script>
import moment from "moment";

import AccountsQuery from "./../../gql/queries/accounts.gql";
import CategorygroupsQuery from "./../../gql/queries/categorygroups.gql";

export default {
  apollo: {
    accounts: {
      query: AccountsQuery,
      variables: function () {
        return {
          where: {
            budget: this.selectedBudget,
          },
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
    },
    categorygroups: {
      query: CategorygroupsQuery,
      variables: function () {
        return {
          where: {
            budget: this.selectedBudget,
          },
          month: moment().format("MM/YYYY"),
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
    },
  },
  props: ["transaction"],
  data() {
    return {
      accounts: [],
      categorygroups: [],
      selectedBudget: this.$store.state.budget,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " €",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    getCategoryTitleById(categoryId) {
      for (const cg of this.categorygroups) {
        for (const c of cg.categories) {
          if (c.id !== categoryId) {
            continue;
          }

          return c.title;
        }
      }
    },
    setCategoryValue(categoryId, value) {
      this.transaction.categoryValues[categoryId] = parseFloat(
        (value * 100).toFixed(0)
      );
    },
  },
};
</script>
