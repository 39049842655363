<template>
  <f7-page name="budgets">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:menu"
          icon
          icon-md="material:menu"
          panel-open="left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Ausgewähltes Budget ändern</f7-nav-title>
    </f7-navbar>
    <f7-list class="no-margin-vertical">
      <f7-list-item
        v-for="budget in budgets"
        :key="budget.id"
        :title="budget.title"
        @click="selectBudget(budget)"
      >
        <f7-icon
          v-if="selectedBudget === budget.id"
          slot="media"
          ios="material:done"
          md="material:done"
        ></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import BudgetsQuery from "./../gql/queries/budgets.gql";
import authenticatedRoute from "./../mixins/authenticated-route.js";

export default {
  mixins: [authenticatedRoute],
  apollo: {
    budgets: {
      query: BudgetsQuery,
      variables: function () {
        return {
          where: {
            users: this.$store.state.user.id,
          },
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  data() {
    return {
      budgets: [],
      selectedBudget: this.$store.state.budget,
    };
  },
  methods: {
    selectBudget({ id }) {
      this.$store.dispatch("setBudget", id);
      localStorage.setItem("selectedBudget", id);
      this.$f7router.navigate(`/budget/${id}/`);
    },
  },
};
</script>
