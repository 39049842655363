<template>
  <f7-page name="budget">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:menu"
          icon
          icon-md="material:menu"
          panel-open="left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ budget.title }}</f7-nav-title>
    </f7-navbar>
    <div
      class="padding-horizontal padding-vertical-half bg-color-primary text-color-white bold display-flex justify-content-space-between"
    >
      <div>
        Zu Budgetieren:
      </div>
      <div :class="getCurrencyColorClass(toBeBudgeted)">
        {{ toBeBudgeted | toCurrency }}
      </div>
    </div>
    <f7-list
      v-for="categorygroup in categorygroups"
      :key="categorygroup.id"
      accordion-list
      class="no-margin-vertical"
    >
      <f7-list-item
        accordion-item
        class="accordion-item-opened"
        :title="categorygroup.title"
      >
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              v-for="category in categorygroup.categories.filter(
                (c) => !c.hidden
              )"
              :key="category.id"
              :title="category.title"
            >
              <span
                :class="getCurrencyColorClass(getCategoryBalance(category))"
              >
                {{ getCategoryBalance(category) | toCurrency }}
              </span>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-fab slot="fixed" position="right-bottom" color="primary">
      <f7-icon ios="f7:plus" md="material:add"></f7-icon>
      <f7-icon ios="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button label="Transaktion" href="/new-transaction/">
          <f7-icon
            ios="material:attach_money"
            md="material:attach_money"
          ></f7-icon>
        </f7-fab-button>
        <f7-fab-button label="Transfer" href="/new-transfer/">
          <f7-icon
            ios="material:compare_arrows"
            md="material:compare_arrows"
          ></f7-icon>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
  </f7-page>
</template>

<script>
import moment from "moment";
import authenticatedRoute from "./../mixins/authenticated-route.js";
import BudgetQuery from "./../gql/queries/budget.gql";
import CategorygroupsQuery from "./../gql/queries/categorygroups.gql";

export default {
  mixins: [authenticatedRoute],
  apollo: {
    budget: {
      query: BudgetQuery,
      variables: function () {
        return {
          id: this.budgetId,
          month: moment().format("MM/YYYY"),
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
    categorygroups: {
      query: CategorygroupsQuery,
      variables: function () {
        return {
          where: {
            budget: this.budgetId,
          },
          month: moment().format("MM/YYYY"),
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  props: {
    budgetId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      budget: {},
      categorygroups: [],
    };
  },
  computed: {
    toBeBudgeted: function () {
      if (!this.budget.toBeBudgeted) return 0;

      return this.budget.toBeBudgeted
        .map((tbb) => tbb.toBeBudgeted)
        .reduce((total, b) => {
          total += b.toBeBudgeted;
          return total;
        }, 0);
    },
  },
  methods: {
    getCategoryBalance: function (category) {
      const balance = category.balance.find(
        (b) => b.month === moment().format("MM/YYYY")
      );

      return balance ? balance.value : 0;
    },
    getCurrencyColorClass: function (value) {
      if (value > 0) return "text-color-teal";
      if (value < 0) return "text-color-red";
      return "";
    },
  },
};
</script>
