<template>
  <f7-list class="no-margin-vertical" no-hairlines-md>
    <f7-list-input
      label="Datum"
      type="datepicker"
      placeholder="Datum des Transfers"
      :value="transfer.date"
      @calendar:change="(value) => (transfer.date = value)"
      readonly
    ></f7-list-input>

    <f7-list-input
      label="Beschreibung"
      type="text"
      placeholder="Beschreibung des Transfers"
      clear-button
      :value="transfer.title"
      @input="transfer.title = $event.target.value"
    ></f7-list-input>

    <f7-list-input label="Betrag" :input="false">
      <money slot="input" v-model="transfer.value" v-bind="money"></money>
    </f7-list-input>

    <f7-list-item
      title="Von"
      smart-select
      :smart-select-params="{ openIn: 'sheet' }"
    >
      <select name="fromAccount" v-model="transfer.fromAccount">
        <option
          v-for="account in fromAccounts"
          :key="account.id"
          :value="account.id"
        >
          {{ account.title }}
        </option>
      </select>
    </f7-list-item>

    <f7-list-item
      title="Nach"
      smart-select
      :smart-select-params="{ openIn: 'sheet' }"
    >
      <select name="toAccount" v-model="transfer.toAccount">
        <option
          v-for="account in toAccounts"
          :key="account.id"
          :value="account.id"
        >
          {{ account.title }}
        </option>
      </select>
    </f7-list-item>
  </f7-list>
</template>

<script>
import AccountsQuery from "./../../gql/queries/accounts.gql";

export default {
  apollo: {
    accounts: {
      query: AccountsQuery,
      variables: function () {
        return {
          where: {
            budget: this.selectedBudget,
          },
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
    },
  },
  computed: {
    fromAccounts() {
      if (!this.transfer.toAccount) {
        return this.accounts;
      }

      return this.accounts.filter((a) => a.id !== this.transfer.toAccount);
    },
    toAccounts() {
      if (!this.transfer.fromAccount) {
        return this.accounts;
      }

      return this.accounts.filter((a) => a.id !== this.transfer.fromAccount);
    },
  },
  data() {
    return {
      accounts: [],
      selectedBudget: this.$store.state.budget,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " €",
        precision: 2,
        masked: false,
      },
    };
  },
  props: ["transfer"],
};
</script>
