export default function currency(value) {
  if (isNaN(parseFloat(value))) {
    return value;
  }

  const showPlusSign = value[0] === "+";

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  const formatedValue = formatter.format(value / 100);

  return showPlusSign ? `+${formatedValue}` : formatedValue;
}
