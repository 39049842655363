<template>
  <f7-popup id="new-transaction-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Neue Transaktion">
          <f7-nav-right>
            <f7-link popup-close>
              Schließen
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <transaction-form :transaction="transaction"></transaction-form>
        <f7-block>
          <f7-row>
            <f7-col>
              <f7-button large raised fill @click="createTransaction()">
                Anlegen
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import moment from "moment";
import showToast from "./../mixins/show-toast.js";
import TransactionFormComponent from "./forms/transaction.vue";

import CreateTransactionMutation from "./../gql/mutations/create-transaction.gql";
import BudgetQuery from "./../gql/queries/budget.gql";
import CategorygroupsQuery from "./../gql/queries/categorygroups.gql";

export default {
  components: {
    transactionForm: TransactionFormComponent,
  },
  mixins: [showToast],
  data() {
    return {
      budget: this.$store.state.budget,
      transaction: {
        type: "Outflow",
        date: [moment().toDate()],
        categories: [],
        categoryValues: {},
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " €",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    async createTransaction() {
      const input = {
        data: {
          ...this.transaction,
        },
      };

      Object.keys(this.transaction.categoryValues).forEach((cId) => {
        if (this.transaction.categories.includes(cId)) return;

        delete input.data.categoryValues[cId];
      });

      input.data.categoryValues = Object.keys(input.data.categoryValues).map(
        (cId) => {
          return {
            category: cId,
            value: input.data.categoryValues[cId],
          };
        }
      );

      input.data.date = moment(input.data.date[0]).format();

      if (this.transaction.categories.length) {
        input.data.value = Object.values(
          this.transaction.categoryValues
        ).reduce((sum, v) => sum + v, 0);
      } else {
        input.data.value = parseFloat((input.data.value * 100).toFixed(0));
      }

      try {
        await this.$apollo.mutate({
          mutation: CreateTransactionMutation,
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: BudgetQuery,
              variables: {
                id: this.budget,
                month: moment().format("MM/YYYY"),
              },
            },
          ],
          update: (store, { data: { createTransaction } }) => {
            const {
              transaction: { id },
            } = createTransaction;
            const { categories, type, categoryValues } = this.transaction;

            if (!id || !categories.length) return;

            const { categorygroups } = store.readQuery({
              query: CategorygroupsQuery,
              variables: {
                where: {
                  budget: this.budget,
                },
                month: moment().format("MM/YYYY"),
              },
            });

            for (const category of categories) {
              const cachedCategory = categorygroups
                .map((cg) => cg.categories)
                .flat()
                .find((c) => c.id === category);

              const balance = cachedCategory.balance.find(
                (b) => b.month === moment().format("MM/YYYY")
              );

              if (type === "Income") {
                balance.value += categoryValues[category];
              } else {
                balance.value -= categoryValues[category];
              }
            }

            store.writeQuery({
              query: CategorygroupsQuery,
              data: { categorygroups },
            });
          },
        });

        const popupElement = document.getElementById("new-transaction-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        this.showToast("Transaktion wurde erfolgreich angelegt!");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
