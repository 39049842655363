<template>
  <f7-panel id="main-menu-panel" left reveal>
    <f7-view>
      <f7-page bg-color="white">
        <f7-navbar title="Pennywise"></f7-navbar>
        <f7-list class="no-margin-vertical">
          <f7-list-item
            title="Übersicht"
            view=".view-main"
            panel-close
            :link="'/budget/' + selectedBudget + '/'"
            :animate="false"
          >
            <f7-icon
              slot="media"
              ios="material:dashboard"
              md="material:dashboard"
            ></f7-icon>
          </f7-list-item>
          <f7-list-item
            title="Konten"
            link="/accounts/"
            view=".view-main"
            panel-close
            :animate="false"
          >
            <f7-icon
              slot="media"
              ios="material:account_balance"
              md="material:account_balance"
              panel-close
              :animate="false"
            ></f7-icon>
          </f7-list-item>
          <f7-list-item
            title="Budgetauswahl"
            link="/budgets/"
            view=".view-main"
            panel-close
            :animate="false"
          >
            <f7-icon
              slot="media"
              ios="material:list"
              md="material:list"
            ></f7-icon>
          </f7-list-item>
          <f7-list-item
            title="Logout"
            view=".view-main"
            panel-close
            :animate="false"
            @click="logout()"
          >
            <f7-icon
              slot="media"
              ios="material:exit_to_app"
              md="material:exit_to_app"
            ></f7-icon>
          </f7-list-item>
        </f7-list>
        <f7-block>
          <p class="text-align-center">
            Angemeldet als:
            <span class="text-color-primary">{{ username }}</span>
          </p>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>

<script>
export default {
  computed: {
    username: function () {
      return this.$store.state.user.username;
    },
    selectedBudget: function () {
      return this.$store.state.budget;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("loginData");

      const panelElement = document.getElementById("main-menu-panel");
      const panel = this.$f7.panel.get(panelElement);
      panel.close();

      this.$f7.views.main.router.navigate(`/login/`);
    },
  },
};
</script>
