<template>
  <f7-popup id="edit-transfer-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Transfer bearbeiten">
          <f7-nav-right>
            <f7-link popup-close>
              Schließen
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <transfer-form :transfer="transferToEdit"></transfer-form>
        <f7-block>
          <f7-row>
            <f7-col>
              <f7-button large raised fill @click="saveTransfer()">
                Speichern
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <f7-button
                large
                raised
                fill
                color="red"
                class="margin-top-half"
                @click="confirmDelete()"
              >
                Löschen
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import showToast from "./../mixins/show-toast.js";
import TransferFormComponent from "./forms/transfer.vue";

import TransferQuery from "./../gql/queries/transfer.gql";
import UpdateTransferMutation from "./../gql/mutations/update-transfer.gql";
import DeleteTransferMutation from "./../gql/mutations/delete-transfer.gql";

export default {
  components: {
    transferForm: TransferFormComponent,
  },
  mixins: [showToast],
  apollo: {
    transfer: {
      query: TransferQuery,
      variables: function () {
        return {
          id: this.transferId,
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$f7.dialog.preloader("Daten werden geladen...");
        } else {
          this.$f7.dialog.close();
        }
      },
      fetchPolicy: "network-only",
    },
  },
  props: {
    transferId: {
      type: String,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      transfer: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " €",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    transferToEdit: function () {
      const t = cloneDeep(this.transfer);
      t.fromAccount = t.fromAccount ? t.fromAccount.id : null;
      t.toAccount = t.toAccount ? t.toAccount.id : null;
      t.date = [moment(t.date).toDate()];
      t.value = t.value / 100;
      return t;
    },
  },
  methods: {
    confirmDelete() {
      this.$f7.dialog.confirm(
        "Sind Sie sicher, dass Sie diesen Transfer löschen wollen?",
        "Transfer löschen",
        () => {
          this.deleteTransfer();
        }
      );
    },
    async deleteTransfer() {
      const input = {
        where: {
          id: this.transferToEdit.id,
        },
      };

      try {
        await this.$apollo.mutate({
          mutation: DeleteTransferMutation,
          variables: {
            input,
          },
        });

        const popupElement = document.getElementById("edit-transfer-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        if (typeof this.onDelete === "function") {
          this.onDelete();
        }

        this.showToast("Transfer wurde erfolgreich gelöscht!");
      } catch (e) {
        console.log(e);
      }
    },
    async saveTransfer() {
      const input = {
        where: {
          id: this.transferToEdit.id,
        },
        data: {
          ...this.transferToEdit,
        },
      };

      input.data.date = moment(input.data.date[0]).format();
      input.data.value = parseFloat((input.data.value * 100).toFixed(0));
      delete input.data.__typename;
      delete input.data.id;

      try {
        const {
          data: {
            updateTransfer: { transfer },
          },
        } = await this.$apollo.mutate({
          mutation: UpdateTransferMutation,
          variables: {
            input,
          },
        });

        if (typeof this.onUpdate === "function") {
          this.onUpdate(transfer);
        }

        const popupElement = document.getElementById("edit-transfer-popup");
        const popup = this.$f7.popup.get(popupElement);
        popup.close();

        this.showToast("Transfer wurde erfolgreich aktualisiert!");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
