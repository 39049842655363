<template>
  <f7-app :params="f7params">
    <main-menu></main-menu>

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/login/"></f7-view>
  </f7-app>
</template>
<script>
import { Device } from "framework7/framework7-lite.esm.bundle.js";
import cordovaApp from "../js/cordova-app.js";
import routes from "../js/routes.js";

import MainMenuComponent from "../components/main-menu.vue";

export default {
  components: {
    mainMenu: MainMenuComponent,
  },
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: "de.maxbernwieser.pennywise", // App bundle ID
        name: "Pennywise", // App name
        theme: "auto", // Automatic theme detection

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
    };
  },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
    });
  },
};
</script>
