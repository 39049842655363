/* eslint-disable no-undef */

// Import Vue
import Vue from "vue";

// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.scss";

// Import App Component
import App from "../components/app.vue";

// Import vuex store
import store from "./../store/store";

// Import apollo
import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";

const apolloClient = new ApolloClient({
  uri: process.env.PENNYWISE.API_URL,
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors.map((e) => e.message).includes("Invalid token.")) {
      localStorage.removeItem("loginData");
      window.location.reload();
    }
  },
  request: (operation) => {
    const token = store.state.user.token;
    if (token) {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    }
  },
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

// Import filters
import currencyFilter from "./../filters/currency";
Vue.filter("toCurrency", currencyFilter);

// Import vue money
import money from "v-money";

// register directive v-money and component <money>
Vue.use(money, { precision: 2 });

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  store,
  apolloProvider,
  el: "#app",

  // Register App Component
  components: {
    app: App,
  },

  render: (h) => h(App),
});
