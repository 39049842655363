import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      username: null,
      token: null,
    },
    budget: null,
  },

  mutations: {
    setToken(state, token) {
      state.user.token = token;
    },
    setUserId(state, id) {
      state.user.id = id;
    },
    setUsername(state, username) {
      state.user.username = username;
    },
    setBudget(state, budget) {
      state.budget = budget;
    },
    // resetState(state) {
    //   state = {
    //     user: {
    //       id: null,
    //       username: null,
    //       token: null,
    //     },
    //     budget: null,
    //   };
    // },
  },

  actions: {
    setToken(context, token) {
      context.commit("setToken", token);
    },
    setUserId(context, id) {
      context.commit("setUserId", id);
    },
    setUsername(context, username) {
      context.commit("setUsername", username);
    },
    setBudget(context, budget) {
      context.commit("setBudget", budget);
    },
    // resetState(context) {
    //   context.commit("resetState");
    // },
  },
});
